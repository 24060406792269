import React from 'react'
import location from "../assets/icons/location.svg";
import phone from "../assets/icons/phone.svg";

export const TopLandingPage = () => {
  return (
    <div className="px-0 lg:px-24 py-0 border-b-[1px]">
      <div className="flex justify-end gap-2 lg:gap-5 mt-4">
        <div className="flex items-center gap-1">
          <img src={phone} alt="phone number" />
          <p className="text-xs font-semibold">
            {/* <b> */}
            <a href="tel:07063561128" className="text-xs font-semibold">
              +234 706 356 1128
            </a>
            {/* </b> */}
          </p>
        </div>
        <div className="flex items-center gap-1">
          <img src={location} alt="location" />
          <p className="text-xs ">129, Iju Road, Ifako-ijaiye, Lagos</p>
        </div>
      </div>
      {/* <hr className=""></hr> */}
    </div>
  );
};
