import React from "react";

export const ServicesCards = (props) => {
  return (
    <>
      {props.services &&
        props.services.map((service) => {
          return (
            <div className="service-card flex flex-col items-center w-72 h-[420px] py-1 px-3 bg-white border-[0.5px] hover:shadow hover:border-transparent shadow-lg gap-1 lg:gap-1">
              <img src={service.serviceImg} alt="serviceIcon" className="service-icon" />
              <p className="service-name text-gray-700 text-center text-base font-semibold leading-[100%]">{service.serviceName}</p>
              <p className="service-body text-gray-700 text-center text-sm font-normal leading-[130%]">{service.serviceBody}</p>
            </div>
          );
        })}
    </>
  );
};
