import React from "react";
import {Link} from "react-router-dom";
import clusterConnect from "../assets/images/clusterConnect.png"

const ClusteredConnect = () => {
  return (
    <div className="flex justify-center items-center flex-col-reverse lg:flex-row h-1/2 bg-[#ffffff] lg:px-40">
      <div className="flex lg:justify-start justify-center text-center lg:text-left lg:items-start items-center flex-col gap-2 p-20 text-gray-800 h-full w-screen">
        <h1 className="text-5xl lg:text-6xl text-center lg:text-left font-bold">
          ClusterConnect
        </h1>
        <h4 className="text-semibold text-lg text-gray-500">
          Attract High-End Residents and Investors
        </h4>
        <h3 className="text-lg lg:text-xl">
          If your estate or multistorey office facility is not generating
          consistent monthly revenue from the internet service provided to
          residents, consider switching to ClusterConnect from Sabi Networks,
          where connectivity meets revenue in a seamless blend of innovation and
          control.
          <br />
          <br />
          Sabi Networks offers more than just internet connectivity – it's a
          transformative solution designed to elevate your estate's financial
          outlook and resident’s satisfaction.
        </h3>

        <div className="flex justify-center flex-col items-center bg-[#f5f5f5] p-4 lg:p-10 gap-2">
          <p className=" text-xl lg:text-2xl font-semibold">
            For more information
          </p>
          <Link
            className="flex py-2 lg:py-3 px-5 text-base lg:text-xl lg:px-9 justify-center items-center border-[0.5px] border-gray-300 shadow-md bg-[#00a85a] hover:bg-[#01632c] text-white"
            to="clusterConnect">
            Learn more here
          </Link>
        </div>
      </div>
      <div className="overflow-hidden w-4/5 self-center">
        <img src={clusterConnect} alt="area-view" className="" />
      </div>
    </div>
  );
};

export default ClusteredConnect;
