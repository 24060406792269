import React from "react";
import cadd from "../assets/images/CADD logo.png";
import touchpoint from "../assets/images/Touchpoint-logo.png";
import icempire from "../assets/images/icempire_logo.png";
import bnl from "../assets/images/BNL Logo.png";
import bamod from "../assets/images/Badmod-Logo.png";

export const Partners = () => {
  return (
    <div className=" flex justify-center items-center flex-col pt-16 bg-[#f2f2f2]">
      <h4 className="text-gray-700 font-semibold text-base lg:text-2xl">
        SOME OF OUR CUSTOMERS
      </h4>
      <div className="flex justify-center items-center flex-wrap px-5 my-2 gap-10 lg:gap-16">
        <img
          src={cadd}
          alt="cadd logo"
          className="partners w-[50px] xl:w-[80px]"
        />
        <img
          src={touchpoint}
          alt="cadd logo"
          className="partners w-[50px] xl:w-[80px]"
        />
        <img
          src={icempire}
          alt="cadd logo"
          className="partners w-[50px] xl:w-[80px]"
        />
        <img
          src={bnl}
          alt="cadd logo"
          className="partners w-[50px] xl:w-[80px]"
        />
        <img
          src={bamod}
          alt="cadd logo"
          className="partners w-[50px] xl:w-[80px]"
        />
      </div>
    </div>
  );
};
