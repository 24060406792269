import React from "react";
import { useState } from "react";
// import axios from "axios";

const ContactUs = () => {
  //  Form states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  // Submit events
  const handleSubmitForm = (e) => {
    const formEle = document.querySelector("form");
    e.preventDefault();
    // alert("Your data has been successfully submitted");
    setMessageSent(true);
    const formData = new FormData(formEle);
    fetch(
      "https://script.google.com/macros/s/AKfycbxLhWHJTwXBW2UU9Fl0kAYYtdTMPTOHZSarJFvvOzaYOjP83vygNT6FYnTBLbYQQ6zg/exec",
      {
        method: "POST",
        body: formData,
      }
    ).then((response) => {
      console.log(response);
      // clearing form fields
      setName("");
      setEmail("");
      setNumber("");
      setSubject("");
      setMessage("");
    });
  };

  return (
    <div
      onClick={() => setMessageSent(false)}
      className="flex justify-center items-center bg-[#f5f5f5] flex-col gap-16 px-24 py-20">
      <div className="flex flex-col items-center gap-5">
        <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
          CONTACT US
        </h3>
        <h2 className="text-gray-700 text-center lg:max-w-2xl text-2xl lg:text-4xl font-light">
          We would love to <span className="text-[#00a85a]">hear</span> from you
        </h2>
      </div>
      <div className="inline-flex items-start justify-center lg:flex-nowrap flex-wrap-reverse gap-12">
        <div className=" max-w-md">
          <h2 className="text-gray-700 text-center lg:max-w-2xl lg:text-left text-2xl lg:text-4xl font-light">
            You can get in touch with us through any of our channels.{" "}
            <span className="text-[#00a85a]">
              We respond to feedback very fast.
            </span>{" "}
            So, feel free to contact us on any of our services.
          </h2>
          <div className=" mt-7 text-center lg:text-left gap-4">
            <h3 className=" text-base text-gray-700 font-semibold">
              Contact Address
            </h3>
            <p className=" text-sm text-gray-700 font-normal">
              129, Iju Road, Ifako Ijaiye, Lagos (Network Operations Centre){" "}
              <br />
              <a href="mailto:info@sabinetworks.com" rel="noreferrer" target="{_blank}"><span className=" text-sm text-[#00a85a] font-normal">
                info@sabinetworks.com
              </span>{" "}</a>
              <br />
              <b className=""><a href="tel:+2347063561128" rel="noreferrer" target="{_blank}">+ 234 706 356 1128</a></b>
            </p>
          </div>
        </div>
        <form
          className="form flex justify-center items-center lg:items-start gap-5 flex-col min-w-[280px]"
          onSubmit={handleSubmitForm}>
          <div className="flex justify-center items-center lg:items-start gap-5 flex-col lg:flex-row">
            <input
              type="text"
              className="w-[280px] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
              placeholder="Name:"
              name="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
            <input
              type="email"
              className="w-[280px] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
              placeholder="Email:"
              name="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>
          <input
            type="phone"
            className=" w-full px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Phone Number:"
            name="Phone"
            onChange={(e) => setNumber(e.target.value)}
            value={number}
            required
          />
          <input
            type="text"
            className=" w-full px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Subject:"
            name="Subject"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            required
          />
          <input
            type="textarea"
            className=" w-full px-2 py-3 flex justify-start items-start border-[1px] border-gray-300 shadow h-40"
            placeholder="Message:"
            name="Message"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            required
          />
          {messageSent ? (
            <p
              className="submit flex py-4 px-8 justify-center items-center bg-[#00a85a] hover:bg-green-700 text-white"
              onClick={() => setMessageSent((prev) => !prev)}>
              Your Message has been sent!
            </p>
          ) : (
            <button
              type="submit"
              className="flex py-4 px-8 justify-center items-center bg-[#00a85a] hover:bg-green-700 text-white"
              style={{ color: "#f5f5f5" }}>
              Send message
              {/* {messageSent && (
                <div className="">
                  <p>Your Message is sent!</p>
                  <p onClick={() => setMessageSent(false)}>Ok</p>
                </div>
              )} */}
            </button>
          )}
        </form>
        {/* <div
          type="submit"
          className="submit flex py-4 px-8 justify-center items-center bg-[#00a85a] hover:bg-green-700"
          style={{color: "#f5f5f5"}}>
          {messageSent ? (
            <div className="submit">
              <p>Your Message is sent!</p>
            </div>
          ) : (
            <p>Send message</p>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default ContactUs;
