import React from "react";
// import {Navbar} from "../components/navbar";
// import {Footer} from "../components/footer";
// import {Footnote} from "../components/footnote";
import {ServicesComponent} from "../components/servicesComponent";
import Navbar1 from "../components/Navbar1";

const Services = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Navbar1 />
      <ServicesComponent />
      {/* <Footer /> */}
      {/* <Footnote /> */}
    </div>
  );
};

export default Services;
