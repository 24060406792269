import React from 'react'
import Navbar1 from '../components/Navbar1'
import Cluster from '../components/Cluster'

const ClusterConnect = () => {
  return (
    <div>
        <Navbar1 />
        <Cluster />
        </div>
  )
}

export default ClusterConnect