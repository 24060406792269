import React from "react";

export const Blogs = () => {
  const blogs = [
    {
      id: 1,
      image:
        "https://logos-world.net/wp-content/uploads/2021/03/Vanguard-Symbol.png",
      heading: "Firm moves to bridge internet gap in Lagos LGA",
      link: "https://www.vanguardngr.com/2023/05/firm-moves-to-bridge-internet-gap-in-lagos-lga/",
    },
    {
      id: 2,
      image:
        "https://logos-world.net/wp-content/uploads/2023/04/The-Independent-Logo-2005.png",
      heading:
        "Sabi Networks Hosts At Rack Centre To Deliver Quality Internet Services To Nigerians",
      link: "https://independent.ng/sabi-networks-hosts-at-rack-centre-to-deliver-quality-internet-services-to-nigerians/",
    },
    {
      id: 3,
      image:
        "https://logos-world.net/wp-content/uploads/2021/03/Vanguard-Symbol.png",
      heading:
        "Sabi Networks launches COREC to bring affordable broadband to Lagos homes, offices",
      link: "https://www.vanguardngr.com/2023/03/sabi-networks-limited-launches-corec-to-bring-reliable-and-affordable-broadband-to-lagos-homes-and-offices/",
    },
    {
      id: 4,
      image:
        "https://1000logos.net/wp-content/uploads/2021/05/The-Guardian-logo.png",
      heading:
        "Sabi Networks to expand COREC project, revolutionize internet connectivity nationwide",
      link: "https://guardian.ng/news/sabi-networks-to-expand-corec-project-revolutionize-internet-connectivity-nationwide/",
    },
    {
      id: 5,
      image:
        "https://logos-world.net/wp-content/uploads/2023/04/The-Sun-Logo.jpg",
      heading:
        "Sabi Networks Limited launches COREC to enhance broadband access across Lagos State",
      link: "https://sunnewsonline.com/sabi-networks-limited-launches-corec-to-enhance-broadband-access-across-lagos-state/",
    },
  ];

  return (
    <div id="blog" className="flex px-2 lg:px-10 py-5 justify-center items-center flex-col h:auto bg-[#00a85a] gap-7">
      <h2 className="text-white text-xl font-semibold lg:text-2xl">As seen in the News</h2>
      <div className="flex justify-center items-start flex-row flex-wrap gap-5">
        {blogs &&
          blogs.map((blog) => {
            return (
              <div className="flex justify-center items-center flex-col gap-y-1  p-2 hover:shadow hover:border-transparent shadow-xl gap-1">
                <img
                  src={blog.image}
                  alt=""
                  className="blog-img h-40 w-auto  shadow-md bg-white"
                />
                <a
                  className="text-white text-left text-sm lg:text-base w-52 font-semibold hover:text-gray-200"
                  href={blog.link}
                  rel="noreferrer"
                  target={"_blank"}>
                  {blog.heading}
                </a>
              </div>
            );
          })}
      </div>
    </div>
  );
};
