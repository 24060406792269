import { Routes, Route} from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import About from "./pages/About";
import Services from "./pages/services";
import InternetPage from "./pages/InternetPage";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import GetStarted from "./pages/GetStarted";
// import {Navbar} from "./components/navbar";
import {Footer} from "./components/footer";
import {Footnote} from "./components/footnote";
import ClusterConnect from "./pages/ClusterConnect";
import whatsapp from "../src/assets/images/whatsapp.png"
// import {ScrollToTop} from "./components/ScrollToTop";
// import Navbar1 from "./components/Navbar1";
// import Terms from "./components/Terms";

function App() {
  return (
    <div className="app">
      {/* <BrowserRouter> */}
      {/* <ScrollToTop /> */}
      {/* <Navbar /> */}
      {/* <Navbar1 /> */}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="internet" element={<InternetPage />} />
        <Route path="clusterConnect" element={<ClusterConnect />} />
        <Route path="contact" element={<Contact />} />
        <Route path="login" element={<Login />} />
        <Route path="getstarted" element={<GetStarted />} />
        {/* <Route path="terms" element={<Terms />} /> */}
      </Routes>
      <a className="whatsapp" href="https://wa.me/2349075485627" target="_blank" rel='noreferrer'><img src={whatsapp} className="chat-icon" alt="whatsapplink" /></a>
      <Footer />
      <Footnote />
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
