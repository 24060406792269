import React from 'react'
import aboutImg from "../assets/images/aboutHero.png"

export const AboutHero = () => {
  return (
    <div
      className="about-hero bg-opacity-75 flex h-40 lg:h-80 px-0 lg:px-96 justify-center items-center bg-auto bg-no-repeat bg-center"
      style={{backgroundImage: "url(" + aboutImg + ")"}}>
      <h2 className="text-white text-2xl lg:text-5xl font-normal">
        About <span className="text-[#00a85a] font-normal">Sabi Networks</span>
      </h2>
    </div>
  );
}
