import React from 'react'

export const Footnote = () => {
  return (
    <div className="footernote flex justify-center items-center bg-gray-700 w-full h-9 lg:h-14">
      <p className="text-white text-base font-normal">
        <span>©</span> {new Date().getFullYear()}{" "}
        <span className="sabi-footernote text-[#fab418] text-base font-semibold">
          Sabi Networks
        </span>
      </p>
    </div>
  );
}
