import React from "react";
import video from "../assets/video/video.mp4";
import { WhySabi } from "./whySabi";

export const LearnMore = () => {
  return (
    <div
      id="whysabi"
      className="learn-more lg:flex justify-center items-center gap-7 py-2 px-4 lg:py-12 lg:px-24 ">
      <WhySabi />
      <video className="video-frame " src={video} controls />
    </div>
  );
};
