import React from "react";
import {ServicesCards} from "./servicesCards";
import wifi from "../assets/icons/wifi.svg";
import shield from "../assets/icons/shield.svg";
import infrastructure from "../assets/icons/infrastructure.svg";
import access from "../assets/icons/access.svg";
// import cctv from "../assets/icons/cctv.svg";
// import software from "../assets/icons/software.svg";
// import security from "../assets/icons/security.svg";
// import colocation from "../assets/icons/colocation.svg";
// import mobile from "../assets/icons/mobile.svg";
import network from "../assets/icons/network.svg";
import monitor from "../assets/icons/monitoring.svg"
import data from "../assets/icons/data.svg";
// import project from "../assets/icons/project.svg";
// import voicecall from "../assets/icons/voicecall.svg";
import wireless from "../assets/icons/wireless.svg"

export const ServicesComponent = () => {
  const services = [
    {
      serviceImg: wifi,
      serviceName: "Broadband Internet",
      serviceBody:
        "Sabi Networks provides cutting-edge broadband Internet solutions designed to cater to the ever-growing demand for high-speed connectivity. Our broadband services empower users with swift access to the digital realm, enabling seamless streaming of media content, rapid file downloads, and the efficient execution of various online activities. At the heart of our broadband offerings lie advanced technologies, including lightning-fast fiber-optic connections and robust wireless networks to ensure that our users experience minimal latency, unparalleled reliability, and consistently high data transfer speeds.",
    },
    {
      serviceImg: infrastructure,
      serviceName: "Network Design and Implementation",
      serviceBody:
        "We understand that the backbone of any successful digital venture lies in its network infrastructure. That's why our Network Design and Implementation service is at the core of what we do. We believe that a well-designed and expertly implemented network is not just a necessity; it's a strategic asset that empowers businesses to thrive in the digital age. Our journey begins with an artful approach to network design. We don't offer one-size-fits-all solutions; we craft customized network architectures that cater specifically to your unique needs.",
    },
    {
      serviceImg: shield,
      serviceName: "Network Security as a Service (NSaaS)",
      serviceBody:
        "In a world where flexibility and mobility are paramount, Sabi Networks is your partner in harnessing the power of Wireless Networking. We understand that being tethered to traditional wired connections can limit productivity and hinder collaboration. That's why we offer cutting-edge Wireless Networking solutions to set you free. At Sabi Networks, we're not just connecting devices; we're connecting people, ideas, and opportunities. Embrace mobility, seamless connectivity, and enhanced collaboration. Discover the freedom of wireless connectivity and elevate your business to new heights with Sabi Networks.",
    },
    {
      serviceImg: wireless,
      serviceName: "Wireless Networking",
      serviceBody:
        "Sabi Networks offers end-to-end IT infrastructure solutions, encompassing hardware, software, and networking components. From server installations and cloud integration to network optimization and scalability, we empower businesses to build and maintain a robust IT foundation that drives productivity and growth.",
    },
    {
      serviceImg: access,
      serviceName: "Remote Access Solutions",
      serviceBody:
        "The need for remote connectivity has never been more critical. At Sabi Networks, we understand that your business doesn't stop when you step out of the office. That's why we offer state-of-the-art Remote Access Solutions designed to keep you seamlessly connected, whether you're working from home, on the road, or anywhere your business takes you. With Sabi Networks' Remote Access Solutions, distance is no longer a barrier to your success. Stay connected, stay productive, and stay ahead of the competition, wherever your journey takes you.",
    },
    // {
    //   serviceImg: cctv,
    //   serviceName: "Security and Surveillance (CCTV Installation)",
    //   serviceBody:
    //     "Enhance your security infrastructure with Sabi Networks' expertly designed and installed CCTV systems. Our surveillance solutions offer round-the-clock monitoring, remote access, and high-definition recording capabilities. Whether it's safeguarding your home or securing a large-scale commercial property, our CCTV installations provide peace of mind.",
    // },
    // {
    //   serviceImg: software,
    //   serviceName: "Software Development",
    //   serviceBody:
    //     "Software development is a process by which standalone or individual software is created using a specific programming language. It involves writing a series of interrelated programming code, which provides the functionality of the developed software. Software development may also be called application development and software design. We have professional software engineers working to give you the best.",
    // },

    // {
    //   serviceImg: colocation,
    //   serviceName: "Infrastructure Colocation",
    //   serviceBody:
    //     "Trust Sabi Networks for secure and reliable infrastructure colocation services. Our data centers offer a dependable environment for hosting your servers, networking equipment, and mission-critical applications. With redundant power, cooling, and connectivity, we provide the ideal colocation solution for businesses of all sizes.",
    // },
    // {
    //   serviceImg: mobile,
    //   serviceName: "Mobile Application Development",
    //   serviceBody:
    //     "Stay ahead in the mobile-first world with Sabi Networks' custom mobile application development services. Our skilled developers craft intuitive, feature-rich mobile apps tailored to your business needs. From iOS and Android to cross-platform solutions, we transform your ideas into powerful mobile experiences. At Sabi Networks, our commitment is to empower businesses with innovative, secure, and reliable solutions across these diverse domains, enabling you to thrive in the digital era.",
    // },
    {
      serviceImg: network,
      serviceName: "Network Upgrades and Scalability",
      serviceBody:
        "At Sabi Networks, we recognize that the digital landscape is constantly evolving, and your network infrastructure should evolve with it. Our Network Upgrades and Scalability service offering is designed to ensure that your network stays at the forefront of technology and grows seamlessly alongside your business. We assess your existing network infrastructure to identify areas for improvement. Whether it's enhancing hardware components, upgrading software, or optimizing configurations, we implement upgrades that boost your network's performance and efficiency.",
    },
    {
      serviceImg: data,
      serviceName: "Data Center Solutions",
      serviceBody:
        "Our Data Center Services Include Data Center Design, Server Deployment, Data Security, Cloud Integration, Monitoring and Management, and Data Backup and Recovery. We craft a scalable and efficient data center architecture that aligns with your organization's needs, ensuring optimal performance and resource utilization. Our team of professional engineers expertly deploy servers and hardware infrastructure, maximizing computing power and storage capacity while minimizing downtime. Our Data Center Solutions are designed to empower your organization with a resilient, high-performance digital core.",
    },
    {
      serviceImg: monitor,
      serviceName: "Network Monitoring and Management",
      serviceBody:
        "Our Network Monitoring and Management services are designed to keep your business running smoothly. Our state-of-the-art monitoring tools keep a watchful eye on your network 24/7. We detect and address issues before they impact your operations, ensuring uninterrupted productivity. We fine-tune your network for optimal speed and efficiency. Say goodbye to bottlenecks and latency, and experience the power of a network that matches your ambitions. Experience the confidence that comes with a well-managed network. Join Sabi Networks in harnessing the power of connectivity, ensuring your business is always at its best.",
    },
    // {
    //   serviceImg: voicecall,
    //   serviceName: "Voice over Internet Protocol",
    //   serviceBody:
    //     "Sabi Networks revolutionizes communication with our VoIP solutions. Seamlessly integrate voice and data, reduce costs, and enhance collaboration with crystal-clear VoIP calls. Whether for personal or business use, our VoIP services ensure reliability and efficiency.",
    // },
  ];
  return (
    <div className="services flex flex-col justify-center items-center py-14 px-24 gap-4 lg:gap-7">
      <h3 className="about-subheading text-gray-700 text-xl font-semibold">
        OUR SERVICES
      </h3>
      <h2 className="text-gray-700 text-center lg:max-w-2xl text-2xl lg:text-4xl font-light">
        The quality of internet service in an office or any location is heavily
        dependent on{" "}
        <span className="text-[#00a85a]">
          the network infrastructure that supports it.
        </span>
      </h2>
      <div className="service-cards flex max-w-[1080px] justify-center items-center content-center gap-y-3 lg:gap-6 gap-x-5 flex-wrap">
        <ServicesCards services={services} />
      </div>
    </div>
  );
};
