import React from "react";
// import yellowstar from "../assets/icons/yellow-star.svg";
// import lightstar from "../assets/icons/light-star.svg";
// import StarRate from "./stars";
import testimonialimg from "../assets/images/testimonialimg.svg";
import { TestimonialCard } from "./testimonialCard";

export const Testimonials = () => {
 const testimonials = [
   {
     id: 1,
     imgcard: testimonialimg,
     testimonialname: "Sina Adeyemi",
     comment:
       "So far, the network has been stable and dependable, with satisfactory download and upload speed. I am quite happy with the service.",
     rating: 4,
   },
   {
     id: 2,
     imgcard: testimonialimg,
     testimonialname: "Somtochukwu Maha",
     comment:
       "Sabi Network's services have proven to be reliable for the past few months I have maintained a subscription. They are responsible in their operations, as they make prior announcements to service maintenance breaks which might lead to some down time, whilst being super polite and responsive whenever they are reached out. I strongly recommend using their services!",
     rating: 5,
   },
  //  {
  //    id: 3,
  //    imgcard: testimonialimg,
  //    testimonialname: "Odunayomi Olubodun",
  //    comment: "Sabi is cool!",
  //    rating: 5,
  //  },
   {
     id: 3,
     imgcard: testimonialimg,
     testimonialname: "Jonathan",
     comment:
       "I have been on the network for few months now. I couldn't be happier. I don suffer for the hands of internet service companies in this Lagos. Thanks Sabi Networks. You are a game changer",
     rating: 5,
   },
   {
     id: 4,
     imgcard: testimonialimg,
     testimonialname: "kachi Uche",
     comment:
       "Really love the service and am a year old and honestly the service has been top notch.",
     rating: 5,
   },
 ];

  return (
    <div
      id="testimonial"
      className="testimonials flex justify-center items-center flex-col px-5 my-14 gap">
      <h2 className="plans-heading  text-gray-700 font-semibold text-lg lg:text-4xl">
        Testimonials
      </h2>
      <p className="sub-heading text-gray-700 text-xl font-normal">
        Join Our Happy Customers
      </p>
      <TestimonialCard testimonials={testimonials} />
    </div>
  );
};
