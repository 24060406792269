import React from "react";
// import {Navbar} from "../components/navbar";
import {TopLandingPage} from "../components/topLanding";
import {Hero} from "../components/hero";
import {Partners} from "../components/partners";
import {Blogs} from "../components/blogs";
import {Plans} from "../components/plans";
import {LearnMore} from "../components/learnmore";
import {Testimonials} from "../components/testimonials";
// import {Footer} from "../components/footer";
// import {Footnote} from "../components/footnote";
import FAQ from "../components/Faq";
import Navbar1 from "../components/Navbar1";
import ClusteredConnect from "../components/ClusteredConnect";

const LandingPage = () => {
  return (
    <div>
      <Navbar1 />
      <TopLandingPage />
      {/* <Navbar /> */}
      <Hero />
      <Partners />
      <Plans />
      <ClusteredConnect />
      <LearnMore />
      <Testimonials />
      <FAQ />
      <Blogs />
      {/* <Footer /> */}
      {/* <Footnote /> */}
    </div>
  );
};

export default LandingPage;
