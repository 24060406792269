import React from "react";

const Map = () => {
  return (
    <div className="flex justify-center items-center flex-col py-8 px-4 lg:px-80 gap-3">
      <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
        COVERAGE AREA
      </h3>
      <p className=" text-justify lg:text-center text-lg font-normal text-gray-700">
        Sabi Networks’ coverage pans across major cities across Nigeria,
        especially for Dedicated Plans only. Please, contact us to confirm
        availability within your area
      </p>
    </div>
  );
};

export default Map;
