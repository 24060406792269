import React from 'react'
// import { Navbar } from '../components/navbar'
// import { Footer } from '../components/footer'
// import { Footnote } from '../components/footnote'
import { Internet } from '../components/Internet'
import Navbar1 from '../components/Navbar1'

const InternetPage = () => {
  return (
    <div>
        {/* <Navbar /> */}
        <Navbar1 />
        <Internet />
        {/* <Footer /> */}
        {/* <Footnote /> */}
    </div>
  )
}

export default InternetPage