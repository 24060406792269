import React from 'react'
// import { Navbar } from '../components/navbar';
// import { Footnote } from '../components/footnote';
import ContactUs from '../components/ContactUs';
import Navbar1 from '../components/Navbar1';

const Contact = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Navbar1 />
      <ContactUs />
      {/* <Footnote /> */}
    </div>
  );
}

export default Contact