import React, {useState} from "react";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is the size of your data Plans?",
      answer: "It is unlimited data across all plans.",
    },
    {
      question: "How long does it take to be connected after payment?",
      answer: "Within 3-5 working days once your payment is comfirmed.",
    },
    {
      question: "Can I upgrade my Internet subscription Plan?",
      answer:
        "Yes you can upgrade to a Higher Internet plan to accommodate your increased capacity usage.",
    },
    {
      question: "Do you have Internet coverage in my area?",
      answer:
        "You can speak with one of our representatives to confirm.",
    },
  ];

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div id="faqs" className="w-lg flex justify-center items-center flex-col mx-auto p-6">
      <h1 className="text-gray-800 text-base lg:text-2xl font-bold mb-4">
        Frequently Asked Questions
      </h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`border min-w-[240px] lg:min-w-[1084px] p-4 ${
              activeIndex === index ? "bg-[#fab418]" : "bg-white"
            }`}>
            <div
              className={`flex justify-between items-center cursor-pointer ${
                activeIndex === index ? "font-semibold" : "font-normal"
              }`}
              onClick={() => toggleFAQ(index)}>
              {faq.question}
              <span className=" h-7 w-7 flex justify-center hover:bg-[#00a85a] items-center rounded-full shadow-md">
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>
            {activeIndex === index && <p className="faq_card mt-2">{faq.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
