import React from "react";
import Map from "./Map";
import residential from "../assets/images/residential.png";
import sme from "../assets/images/sme.png";
import dedicated from "../assets/images/dedicated.png";
import wholesale from "../assets/images/wholesale.png";
import clusteredconnect from "../assets/images/clusteredconnect.jpg"

export const Internet = () => {
  const internet = [
    {
      heading: "Residential Internet",
      image: residential,
      writeUp:
        "Sabi Networks’ residential internet service plans are specifically designed for individual households and residential subscribers. These plans are tailored to meet the internet needs of private homes rather than businesses or organizations. Whether you work from home or simply need the internet to enjoy seamless internet access with your family, choose Sabi Networks",
    },
    {
      heading: "SMEs",
      image: sme,
      writeUp:
        "Our SME plan combines the features of dedicated internet and the burstable feature of a shared internet connection. This internet plan is designed for small to medium-sized organizations who need to strike a balance between cost-effectiveness and performance, making it easier for them to meet their internet requirements without breaking the bank.",
    },
    {
      heading: "Dedicated Internet",
      image: dedicated,
      writeUp:
        "Our Dedicated internet offers organizations, particularly those with high bandwidth requirements, such as data centers, large enterprises, or organizations with critical online operations, exclusivity, consistent performance, high reliability, and symmetrical speeds. It provides a dedicated and uncontested connection to the internet.",
    },
    {
      heading: "Wholesale",
      image: wholesale,
      writeUp:
        "As a wholesale provider, our primary focus is to cater to the unique requirements of ISPs, resellers, and corporate entities. We offer a robust and scalable network infrastructure that enables our clients to deliver exceptional internet services to their customers. By partnering with Sabi Networks, you gain access to a reliable backbone network that ensures seamless connectivity and exceptional performance. Call 0908 570 3771 to get a Quote today",
    },
    {
      heading: "ClusterConnect",
      image: clusteredconnect,
      writeUp:
        "ClusterConnect is a product of Sabi Networks aimed at empowering residential or industrial clusters, and multi-storey office and residential buildings with 100% control over its Internet utility. We collaborate with estates’ management, facility managers, and property owners to integrate a centrally-managed internet service for their residents/occupants, while you generate consistent passive revenue on a monthly basis.",
    },
  ];
  return (
    <>
      <div className="internet flex flex-col items-center gap-11 py-11 bg-[#f1f1f1]">
        <div className="internet-hero flex flex-col items-center gap-5">
          <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
            OUR INTERNET OFFERING
          </h3>
          <h2 className="text-gray-700 text-center lg:max-w-2xl text-2xl lg:text-4xl font-light">
            Thinking of choosing a{" "}
            <span className="text-[#00a85a]">broadband connection </span>for
            your home or business?
          </h2>
        </div>
        <div className="gap-y-5">
          <div className=" inline-flex items-center gap-5 justify-center lg:flex-nowrap flex-wrap-reverse">
            <img src={internet[0].image} alt="" className="rounded-lg" />
            <div className=" flex justify-center items-center flex-col py-2 px-3 lg:px-20 bg-white lg:rounded-l-lg shadow-md h-[343px] gap-5">
              <h2 className=" text-center text-4xl font-semibold text-gray-800">
                {internet[0].heading}
              </h2>
              <p className="text-gray-700 text-base lg:text-2lg font-normal text-justify">
                {internet[0].writeUp}
              </p>
            </div>
          </div>
          <div className="inline-flex items-center gap-5 justify-center lg:flex-nowrap flex-wrap">
            <div className="flex justify-center items-center flex-col py-2 px-3 lg:px-20 bg-white lg:rounded-r-lg shadow-md h-[343px] gap-5">
              <h2 className=" text-center text-4xl font-semibold text-gray-800">
                {internet[1].heading}
              </h2>
              <p className="text-gray-700 text-base lg:text-2lg font-normal text-justify">
                {internet[1].writeUp}
              </p>
            </div>
            <img src={internet[1].image} alt="" className="rounded-lg" />
          </div>
          <div className="inline-flex items-center gap-5 justify-center lg:flex-nowrap flex-wrap-reverse">
            <img src={internet[2].image} alt="" className="rounded-lg" />
            <div className="flex justify-center items-center flex-col py-2 px-3 lg:px-20 bg-white lg:rounded-l-lg shadow-md h-[343px] gap-5">
              <h2 className=" text-center text-4xl font-semibold text-gray-800">
                {internet[2].heading}
              </h2>
              <p className="text-gray-700 text-base lg:text-2lg font-normal text-justify">
                {internet[2].writeUp}
              </p>
            </div>
          </div>
          <div className="inline-flex items-center gap-5 justify-center lg:flex-nowrap flex-wrap">
            <div className="flex justify-center items-center flex-col py-2 px-3 lg:px-20 bg-white lg:rounded-r-lg shadow-md h-[343px] gap-5">
              <h2 className=" text-center text-4xl font-semibold text-gray-800">
                {internet[3].heading}
              </h2>
              <p className="text-gray-700 text-base lg:text-2lg font-normal text-justify">
                {internet[3].writeUp}
              </p>
            </div>
            <img src={internet[3].image} alt="" className="rounded-lg" />
          </div>
          {/* <div className="inline-flex items-center gap-5 justify-center lg:flex-nowrap flex-wrap-reverse">
            <div className="lg:w-[600px] h-96"></div>
            <img src={internet[4].image} alt="" className="w-[auto] h-80" />
            <div className="flex justify-center items-center flex-col py-2 px-3 lg:px-20 bg-white lg:rounded-l-lg shadow-md h-[343px] gap-5">
              <h2 className=" text-center text-4xl font-semibold text-gray-800">
                {internet[4].heading}
              </h2>
              <p className="text-gray-700 text-base lg:text-2lg font-normal text-justify">
                {internet[4].writeUp}
              </p>
            </div>
          </div> */}
          <div className="">
            <img src="" alt="" />
            <div className=""></div>
          </div>
        </div>
      </div>
      <Map />
    </>
  );
};
