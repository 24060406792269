import React from "react";
import Navbar1 from "../components/Navbar1";
// import {Navbar} from "../components/navbar";
// import {Footer} from "../components/footer";
// import {Footnote} from "../components/footnote";

const Login = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Navbar1 />
      <div className="flex justify-center items-center flex-col p-10">
        <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
          LOGIN PAGE
        </h3>
        <h1 className=" text-4xl font-extrabold text-gray-700 text-center">
          We're working on it!
        </h1>
      </div>
      {/* <Footer /> */}
      {/* <Footnote /> */}
    </div>
  );
};

export default Login;
