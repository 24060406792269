import React from "react";
// import {Navbar} from "../components/navbar";
// import {Footer} from "../components/footer";
// import {Footnote} from "../components/footnote";
import {AboutHero} from "../components/aboutHero";
import {AboutDetails} from "../components/aboutDetails";
import Navbar1 from "../components/Navbar1";

const About = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Navbar1 />
      <AboutHero />
      <AboutDetails />
      {/* <Footer />
      <Footnote /> */}
    </div>
  );
};

export default About;
