import React from "react";
import innovation from "../assets/icons/innovation.svg";
import partners from "../assets/icons/partners.svg";
import lock from "../assets/icons/lock.svg";
import guarantee from "../assets/icons/guarantee.svg";
import people from "../assets/icons/people.svg";

export const AboutDetails = () => {
  const values = [
    {
      img: innovation,
      name: "Innovation",
      details:
        "We believe in pushing the boundaries of what's possible. Innovation is the engine that drives progress, and we constantly seek new ways to enhance our services and exceed client expectations.",
    },
    {
      img: partners,
      name: "Partnership",
      details:
        "Collaboration is key to success. We forge strong partnerships with our clients, understanding their unique needs and goals to provide tailored solutions that deliver real results.",
    },
    {
      img: lock,
      name: "Security",
      details:
        "In an increasingly interconnected world, security is paramount. We are dedicated to safeguarding our clients' data and networks, ensuring they remain protected from evolving threats.",
    },
    {
      img: guarantee,
      name: "Reliability",
      details:
        "Our commitment to reliability is unwavering. We provide robust, scalable solutions that clients can depend on, even in the face of growing demands and challenges.",
    },
    {
      img: people,
      name: "Client-Centric",
      details:
        "Everything we do is centered around our clients. Their success is our success, and we go above and beyond to provide exceptional service, support, and expertise.",
    },
  ];

  return (
    <div className="about-details flex px-4 text-justify lg:px-12 py-3 lg:py-24 justify-center items-center flex-col gap-2 lg:gap-5">
      <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
        About Us
      </h3>
      <h2 className="text-gray-700 text-center lg:max-w-2xl text-2xl lg:text-4xl font-light">
        Empowering Connectivity for a{" "}
        <span className="text-[#00a85a]">Digital World</span>
      </h2>
      <p className="text-base lg:text-lg px-4 lg:px-8 font-light leading-7">
        At Sabi Networks, we are on a mission to empower the world with seamless
        connectivity, transforming the way people live, work, and communicate.
        As a leader in the field of network infrastructure and technology
        solutions, we are dedicated to helping individuals, businesses, and
        communities thrive in an ever-evolving digital landscape.
      </p>
      <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
        Our Journey Begins
      </h3>
      <p className="text-base lg:text-lg px-4 lg:px-8 font-light leading-7">
        Founded in 2019 with a vision of bridging the connectivity gap and
        driving technological advancement, Sabi Networks has grown from humble
        beginnings into a trusted partner for clients nationwide. Our journey is
        a testament to our passion for innovation and our commitment to
        excellence.
      </p>
      <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
        Our Core Values
      </h3>
      <p className="text-base lg:text-lg px-4 lg:px-8 font-light leading-7">
        At the heart of our company are our core values:
      </p>
      <ul className="flex items-center justify-center flex-wrap text-center gap-5 my-2 lg:my-5">
        {values &&
          values.map((value) => {
            return (
              <li className="flex items-center justify-start flex-col w-80 h-52 lg:h-56 p-2 lg:p-5 gap-4 border-2 bg-gray-100">
                <div className=" flex flex-row-reverse justify-between items-center w-72">
                  <img
                    src={value.img}
                    alt="about-icon"
                    className=" w-11 h-11"
                  />
                  <h3 className=" text-gray-700 text-xl font-medium">{value.name}</h3>
                </div>
                <p className=" text-gray-700 font-light text-justify">
                  {value.details}
                </p>
              </li>
            );
          })}
      </ul>
      <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
        Our Commitment to Excellence
      </h3>
      <p className="text-base lg:text-lg px-4 lg:px-8 font-light leading-7">
        We are driven by a relentless pursuit of excellence in all that we do.
        Our team of dedicated professionals brings a wealth of knowledge,
        experience, and passion to every project. We stay at the forefront of
        technological advancements to provide innovative solutions that keep our
        clients ahead of the curve.
      </p>
      <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
        Join the Sabi Networks Family
      </h3>
      <p className="text-base lg:text-lg px-4 lg:px-8 font-light leading-7">
        Whether you're an individual seeking high-speed internet for your home,
        a business aiming to optimize your network infrastructure, or a
        community looking to embrace the digital age, Sabi Networks is here to
        help you succeed. We invite you to experience the Sabi Networks
        difference and embark on a journey of connectivity, innovation, and
        limitless possibilities.
        <br /> <br />
        Let's connect your world to a brighter future.
        <br />
        🌐 Welcome to Sabi Networks.
      </p>
    </div>
  );
};
