import React, {useState} from "react";
import {FaStar} from "react-icons/fa";
// import rating from "./testimonials";

const StarRate = (props) => {
  //   const [rating, setRating] = useState(null);
  const [hoverFill, setHoverFill] = useState(null);
//   rating
  return (
    <div className="star border-0">
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1;

        return (
          <button
            className="star"
            key={index}
            onMouseEnter={(rating) => setHoverFill(ratingValue)}
            onMouseLeave={() => setHoverFill(null)}
            // onClick={() => setRating(ratingValue)}
          >
            <FaStar
              size={20}
              style={{
                color:
                  ratingValue <= (hoverFill || props.rating) ? "#ffe101" : "#ccc",
              }}
              //   onChange={() => setRating(ratingValue)}
              //   value={ratingValue}
            />
          </button>
        );
      })}
    </div>
  );
};

export default StarRate;
