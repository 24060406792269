import React from "react";
import slide1 from "../assets/images/Sabi Network Estate 1.png";
import slide2 from "../assets/images/Sabi Network Estate 2.png";
import slide3 from "../assets/images/Sabi Network Estate  3.png";
import slide4 from "../assets/images/Sabi Network Estate 4.png";
import slide5 from "../assets/images/Sabi Network Estate 5.png";
import slide6 from "../assets/images/Sabi Network Estate 6.png";
import ClusterGallery from "./ClusterGallery";
import {Link} from "react-router-dom";
// import pdf from ""

const Cluster = () => {
  const galleryImages = [
    {
      img: slide1,
    },
    {
      img: slide2,
    },
    {
      img: slide3,
    },
    {
      img: slide4,
    },
    {
      img: slide5,
    },
    {
      img: slide6,
    },
  ];

  return (
    <div className="text-gray-800 flex justify-center text-center items-center flex-col py-10 ">
      <div className="px-10 md:px-20 lg:px-32 xl:px-48 2xl:px-64 gap-9">
        <h1 className="text-5xl lg:text-6xl font-bold">ClusterConnect</h1>
        <h4 className="text-semibold text-lg text-gray-500">
          Find all you need to know about this service here
        </h4>
        <p className=" text-[20px] lg:w-[1080px]">
          ClusterConnect is a product of Sabi Networks aimed at empowering
          residential or industrial clusters, and multi-storey office and
          residential buildings with 100% control over its Internet utility.
          <br /> We collaborate with estates’ management, facility managers, and
          property owners to integrate a centrally-managed internet service for
          their residents/occupants, while you generate consistent passive
          revenue on a monthly basis.
        </p>
        <p className="text-xl font-semibold px-3 mt-2">
          Download Our Presentation{" "}
          <Link
            to="/SABI Networks Limited Presentation.pdf"
            target="_blank"
            download
            className="text-red-600">
            here
          </Link>
        </p>
      </div>
      <ClusterGallery galleryImages={galleryImages} />
    </div>
  );
};

export default Cluster;
