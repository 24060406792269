import React from "react";
import upto1 from "../assets/icons/upto1.svg";
import upto2 from "../assets/icons/upto2.svg";
import upto3 from "../assets/icons/upto3.svg";
import naira from "../assets/icons/naira.svg";
import unlimited from "../assets/icons/unlimited.svg";
import {Link} from "react-router-dom";
import sme from "../assets/images/sme-picture.jpg";

export const Plans = () => {
  const plans = [
    {
      id: 1,
      name: "Connect Lite",
      desc: "",
      planCardBg: "#ffffff",
      capacity: 10,
      price: "17,500",
      uptoImage: upto1,
      feature1: "HD streaming",
      feature2: "Streaming Music",
      feature3: "Streaming standard definition video",
      feature4: "Email",
      feature5: "Social Media",
      feature6: "Multiple users",
      button: [
        {
          fontColor: "#00A85A",
          background: "#ffffff",
        },
      ],
    },
    {
      id: 2,
      name: "Connect Plus",
      desc: "",
      planCardBg: "#d0f0c0",
      capacity: 15,
      price: "25,000",
      uptoImage: upto2,
      feature1: "Ultra HD streaming",
      feature2: "Frequent gaming",
      feature3: "Streaming 1080p high definition video",
      feature4: "Excellent web browsing",
      feature5: "Video calling",
      feature6: "Experience the high speeds",
      // feature7:
      //   "One-Time Installation Cost: #150,000 (Inclusive of First month subscription)",
      button: [
        {
          fontColor: "#00A85A",
          background: "#ffffff",
        },
      ],
    },
    {
      id: 3,
      name: "Connect Pro",
      desc: "",
      planCardBg: "#fcd12a",
      capacity: 20,
      price: "30,000",
      uptoImage: upto3,
      feature1: "Streaming multiple shows in HD",
      feature2: "Simultaneous gaming",
      feature3: "Streaming 4k Ultra defintion video",
      feature4: "Large files Download and Transfers",
      feature5: "Perfect for households with multiple devices",
      feature6: "  ",
      // feature7:
      //   `One-Time Installation Cost: #158,500 (Inclusive of First month subscription)`,
      button: [
        {
          fontColor: "#00A85A",
          background: "#ffffff",
        },
      ],
    },
  ];

  return (
    <div id="plans" className="plans flex flex-col justify-center items-center gap-5 lg:gap-10 my-9 pb-10">
      {/* <h2 className="plans-heading text-gray-700 font-semibold text-lg lg:text-4xl">
        Choose your Plan
      </h2> */}

      <div className="plan-cards flex justify-center items-end flex-wrap gap-10">
        <div className="flex justify-center items-center flex-col gap-2">
          <h3 className="residentials-plans text-gray-700 text-xl font-semibold border-2 p-2 shadow-xl">
            Residential Plans
          </h3>
          <div className="plan-cards flex justify-center items-center flex-wrap gap-10">
            {plans &&
              plans.map((plan) => {
                return (
                  <div className="plan-card flex flex-col items-center gap-1">
                    <p className="plan-card-heading text-gray-700 text-base lg:text-lg font-normal">
                      {plan.name}
                    </p>
                    <p className="plan-card-heading text-gray-700 text-xs lg:text-base font-normal">
                      {plan.desc}
                    </p>
                    <div
                      className="plan-card-body flex py-4 px-3 justify-center items-center flex-col gap-1 box-border border-[0.5px] shadow-md h-[500px] w-80"
                      style={{backgroundColor: plan.planCardBg}}>
                      <div className="upto-card relative flex justify-center items-center">
                        <div className="upto-details absolute flex flex-col items-center gap-[-50px] ">
                          <p className="upto text-gray-700 text-xs font-normal">
                            Upto
                          </p>
                          <p className="capacity text-gray-700 text-sm font-bold lg:text-base">
                            {plan.capacity}
                          </p>
                          <p className="mbps text-gray-700 text-xs font-normal">
                            Mbps
                          </p>
                        </div>
                        <img src={plan.uptoImage} alt="" className="upto-img" />
                      </div>
                      {/* <hr className="w-[232px] border-gray-400 " /> */}
                      <div className="plan-card-details flex flex-col items-center gap-2">
                        <div className="plan-card-details-1 flex items-center gap-1">
                          <img
                            src={unlimited}
                            alt="sign-img"
                            className="sign1"
                          />
                          <p className="unlimited-internet text-gray-700 text-base lg:text-lg font-normal">
                            Unlimited Downloads
                          </p>
                        </div>
                        <div className="plan-card-details-2 flex items-center gap-1">
                          <img src={naira} alt="sign-img" className="sign2" />
                          <p className="unlimited-internet text-gray-700 text-base font-normal lg:text-lg">
                            <span className="price text-gray-700 text-base lg:text-lg font-semibold">
                            &#8358;{plan.price}
                            </span>{" "}
                            per month
                            <br />
                          </p>
                        </div>
                        <ul className="text-center text-gray-700 text-base lg:text-lg font-normal">
                          <li>{plan.feature1}</li>
                          <li>{plan.feature2}</li>
                          <li>{plan.feature3}</li>
                          <li>{plan.feature4}</li>
                          <li>{plan.feature5}</li>
                          <li>{plan.feature6}</li>
                          <li className=" w-60">{plan.feature7}</li>
                        </ul>
                        <p className="text-gray-700 text-base font-bold"></p>
                      </div>

                      {plan.button &&
                        plan.button.map((data) => {
                          return (
                            <Link
                              className="plan-card-btn flex px-14 py-2 justify-center items-center  border-[0.5px] border-gray-300 shadow-lg"
                              to="getstarted"
                              style={{
                                color: data.fontColor,
                                backgroundColor: data.background,
                              }}>
                              Get Started
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <img
          src={sme}
          alt="SME"
          className="justify-center items-center h-60 lg:h-[400px] shadow-2xl"
        />
      </div>

      {/* <div className="text-gray-800 text-center w-96 p-1 lg:p-3 border-2 shadow-2xl bg-[#FAB418]">
        <h2 className="text-lg font-bold">New Residential Setup Cost</h2>
        <h4>
          COREC - <b>#47,000</b>
        </h4>
        <h4>
          Microwave - <b>#150,000</b> (Inculsive of one-time Connect Plus plan){" "}
        </h4>
      </div> */}
      {/* <div className="SME AND DEDICATED ">
        <h4 className="text-gray-800 text-center px-10">
          To get more details on the the <b>SME bundles</b> and{" "}
          <b>Dedicated plan</b>,{" "}
          <Link to="/contact" className="text-[#00A85A]">
            <b>Click Here</b>
          </Link>{" "}
        </h4>
      </div> */}
    </div>
  );
};
