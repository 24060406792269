import React from "react";
import {useState} from "react";
// import {Link} from "react-router-dom";

const GetStartedComponent = () => {
  // form state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  // const [identity, setIdentity] = useState("");
  // const [idnumber, setIdnumber] = useState("");
  const [plan, setPlan] = useState("");
  const [getstarted, setGetStarted] = useState(false);
  // const [terms, setTerms] = useState("");

  // Submit Events
  const handleSubmit = (e) => {
    const formEle = document.querySelector("form");
    setGetStarted(true);
    e.preventDefault();
    const formData = new FormData(formEle);
    fetch(
      "https://script.google.com/macros/s/AKfycbxBD4jziM0n3JtMu2Gi9LM_xF7x9jJHFcUssuKsduA96ZTU35mZ9ei8u6E1ElKnOFQd/exec",
      {
        method: "POST",
        body: formData,
      }
    ).then((response) => {
      console.log(response);
      // Clearing form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setAddress("");
      // setIdentity("");
      // setIdnumber("");
      setPlan("");
      // setTerms("");
      alert("Your data has been successfully submitted");
    });
  };

  return (
    <div
      onClick={() => setGetStarted(false)}
      className="form flex flex-col justify-center items-center py-5 lg:py-20 px-2 lg:px-32 gap-10 bg-[#f2f2f2]">
      <div className="flex flex-col items-center gap-5">
        <h3 className="about-subheading text-gray-700 text-xl font-semibold ">
          GET STARTED
        </h3>
        <h2 className="text-gray-700 text-center lg:max-w-2xl text-2xl lg:text-4xl font-light">
          Please provide the following information to setup your{" "}
          <span className="text-[#00a85a]">account</span>
        </h2>
      </div>
      <form
        className="form flex justify-center items-start gap-5 flex-col w-4/5 lg:max-w-5xl"
        onSubmit={handleSubmit}>
        <div className="flex justify-center w-full items-center lg:items-start gap-5 flex-col lg:flex-row">
          <input
            type="text"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="First Name"
            name="First"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            required
          />
          <input
            type="text"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Last Name"
            name="Last"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            required
          />
        </div>
        <div className="flex justify-center w-full items-center lg:items-start gap-5 flex-col lg:flex-row">
          <input
            type="email"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Email Address"
            name="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <input
            type="text"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Phone Number"
            name="Phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            required
          />
        </div>
        <input
          type="text"
          className=" w-full px-2 py-3 border-[1px] border-gray-300 shadow"
          placeholder="Contact Address (As seen on google map)"
          name="Contact"
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          required
        />
        {/* <div className="flex justify-center w-full items-center lg:items-start gap-5 flex-col lg:flex-row">
          <select
            className=" w-full px-2 py-3 border-[1px] border-gray-300 shadow"
            name="Identity"
            value={identity}
            onChange={(e) => setIdentity(e.target.value)}
            required>
            <option value="">Select a means of Identification</option>
            <option value="NIN">NIN</option>
            <option value="Driver's License">Driver's License</option>
            <option value="International Passport">
              International Passport
            </option>
            <option value="International Passport">
              Voter's card
            </option>
          </select>
          <input
            type="text"
            className="w-[100%] lg:w-[50%] px-2 py-3 border-[1px] border-gray-300 shadow"
            placeholder="Kindly Fill in the ID number here"
            name="IdentityNumber"
            onChange={(e) => setIdnumber(e.target.value)}
            value={idnumber}
            required
          />
        </div> */}
        <select
          className=" w-full px-2 py-3 border-[1px] border-gray-300 shadow"
          name="Plan"
          value={plan}
          onChange={(e) => setPlan(e.target.value)}
          required>
          <option value="">Select plan</option>
          <option value="Connect Lite - #17,500">Connect Lite - &#8358;17,500</option>
          <option value="Connect Plus - #25,000">Connect Plus - &#8358;25,000</option>
          <option value="Connect Pro - #30,000">Connect Pro - &#8358;30,000</option>
        </select>
        {/* <label className=" text-justify">
          <b>TERMS OF SERVICE</b>
          <br />
          Sabi Networks Limited shall submit its invoices monthly/yearly in
          advance to the customer for subscribed service due in respect of the
          service procured from Sabi Networks. Payment shall be made by the
          customer monthly/yearly in advance and within 7 days of receipt of
          Sabi Networks’ invoice at the agreed price between Sabi Networks and
          the Customer. In accordance with the provisions of the Value Added Tax
          (VAT) Act, all services invoiced shall attract VAT at the applicable
          ruling rate.
          <br />
          Termination of Operational Link: The customer reserves the right to
          terminate an operational link at the expiry of this Agreement by
          giving written notice of a minimum of Thirty (30) days to the
          expiration of the Service Order form. However, in the event that the
          service fails abysmal low below 69% percent availability in a month,
          the customer reserves the right to terminate this service order form.
          <br />
          Service Performance Levels: Sabi Networks assumes professional and
          technical responsibility for its performance which will be in
          accordance with recognized professional standards employed by service
          provider performing work of comparable nature. Sabi Networks shall not
          be penalized for service outages derived from scheduled and mutually
          agreed maintenance; Sabi Networks shall give the customer a minimum of
          7 days' notice in writing for any planned maintenance or upgrade of
          its equipment such that will cause an outage on the link.
          <br />
          Customer Premises Equipment (CPE) damage: The Equipment installed and
          at Customer’s Custody is leased to the customer by the company and the
          Customer is obliged to guarantee the preservation of the Equipment at
          their own expense in a way that excludes the Equipment’s theft, loss,
          destruction, harming and/or damaging) including as a result of fire,
          moisture or heat related damages, lightning and/or other similar
          events). If circumstances described above occur, the Customer will be
          liable for the replacement of the equipment.
          <br />
          Breach: If either party’s System adversely affects the normal
          operation of the other Party’s System or is a threat to any person’s
          safety, the other Party shall agree whether such interruption emanates
          from the other party’s system to enable it suspend operation to the
          extent necessary of such of its obligations hereunder, and for such
          period as may be reasonable to ensure the operation of its System or
          reduce the threat to safety. The Customer hereby undertakes that the
          leased provided to Customer further to this Agreement shall be for the
          exclusion use of the Customer and shall not in any circumstance be
          sub-let, resold or its benefit offered in such manner to a third
          party. Breach of this covenant shall constitute a material breach and
          shall allow Sabi Networks terminate this Agreement.
          <br />
          If a party is in material breach of this Agreement, the other Party
          may serve a written notice (the “breach notice”) on the Party in
          breach and requiring it to be remedied within 10 business days from
          the date of receipt of such breach notice Customer may, otherwise;
          Sabi Networks may suspend the services until the breach is remedied.
          <br />
          Force Majeure: Neither party shall be liable for any breach of this
          Agreement caused by act of God, insurrection or civil disorder, war or
          military operations, national or local emergency acts or omissions of
          government, highway authority or other competent authority, compliance
          with any statutory obligation, industrial disputes of any kind
          (whether or not involving Customer or Sabi Networks’ employees), fire,
          lightning, explosion, flood, subsidence, weather of exceptional
          severity, fiber disruption, vandalism acts or omissions of persons for
          whom neither Party is responsible or any other cause whether similar
          or dissimilar outside its reasonable control and any such event or
          circumstance is a force majeure.
          <br />
          The party claiming relief under this clause shall promptly notify the
          other in writing of the existence of the Force Majeure Event relied
          on, the anticipated length of delay, the cause of the delay and the
          timetable by which and remedial measures will be implemented. If the
          Force Majeure Event is not remedied within one month either party may
          terminate the service forthwith upon providing written notice thereof
          to the other party. The Party initially affected by a force majeure
          shall promptly notify the other of the estimated extent and duration
          of its inability to perform or delay in performing its obligations
          (“force majeure notifications”). Upon cessation of the force majeure
          the Party initially affected by a force majeure shall promptly notify
          the other of such cessation.
          <br />
          Confidentiality: The Parties shall preserve, safeguard and keep in
          confidence all of the Confidential/Proprietary Information of the
          other party may use Confidential/Proprietary Information received from
          the other Party solely for the purposes hereunder and in the event
          that this Agreement is terminated; however, each party, at the request
          of the other, shall return or destroy Confidential/Proprietary
          Information received from the other party which comprises, contain or
          otherwise disclose Confidential/Proprietary Information of the
          disclosing party.
          <br />
          <b>
            ENTER YOUR FULL NAME IN BLOCK FORM TO ACCEPT THE TERMS OF SERVICE
          </b>
          <br />
          <input
            name="Terms"
            type="text"
            className=" w-full px-2 py-3 border-[1px] border-gray-300 shadow mt-2"
            value={terms}
            // checked={true}
            onChange={(e) => setTerms(e.target.value)}
            required
          />
        </label> */}
        {getstarted ? (
          <p
            className="submit flex py-4 px-8 justify-center items-center bg-[#00a85a] hover:bg-green-700 text-white"
            onClick={() => setGetStarted((prev) => !prev)}>
            Your Message has been sent!
          </p>
        ) : (
          <button
            type="submit"
            className="flex py-4 px-8 justify-center items-center bg-[#00a85a]"
            style={{color: "#f5f5f5"}}>
            Submit
          </button>
        )}
      </form>
    </div>
  );
};

export default GetStartedComponent;
