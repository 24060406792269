import React, {useReducer, useEffect} from "react";
// import {WhySabiCard} from "./whySabiCard";
import fast from "../assets/icons/fast.svg";
import reliable from "../assets/icons/reliable.svg";
import tailored from "../assets/icons/tailored.svg";
import transparency from "../assets/icons/transparency.svg";
import flexibility from "../assets/icons/flexibility.svg";
import support from "../assets/icons/support.svg";

export const WhySabi = () => {
  const sabinetworks = [
    {
      whysabiImg: fast,
      whysabi: "Blazing Fast Speeds",
      whysabiDetails:
        "We offer lightning-fast internet speeds, ensuring seamless browsing, smooth streaming, and lag-free online experiences.",
    },
    {
      whysabiImg: reliable,
      whysabi: "Reliable Connection",
      whysabiDetails:
        "Say goodbye to disruptions. Our robust network infrastructure ensures a stable and dependable internet connection, 24/7.",
    },
    {
      whysabiImg: tailored,
      whysabi: "Tailored Plans",
      whysabiDetails:
        "Choose from a variety of plans designed to suit your specific needs. From basic to premium, we have the right package for everyone.",
    },
    {
      whysabiImg: transparency,
      whysabi: "Transparent Pricing",
      whysabiDetails:
        "No hidden costs or surprises. Our pricing is straightforward and competitive, providing you with the best value for your money.",
    },
    {
      whysabiImg: flexibility,
      whysabi: "Flexibility",
      whysabiDetails:
        "We understand that your internet needs may change over time. That's why we offer flexible plans, allowing you to upgrade or downgrade as needed.",
    },
    {
      whysabiImg: support,
      whysabi: "Constant Support",
      whysabiDetails:
        "Our support team is well-equipped to assist you with any questions or concerns you may have. We are just a call or email away.",
    },
  ];

  const initialState = {
    currentIndex: 0,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "NEXT":
        return {
          ...state,
          currentIndex: (state.currentIndex + 1) % sabinetworks.length,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({type: "NEXT"});
    }, 4000); // Change testimonial every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="why-sabi flex flex-col items-center px-7 lg:px-16 gap-4 lg:gap-9 my-5">
      <h3 className="about-subheading text-gray-700 text-center text-xl font-semibold ">
        WHY CHOOSE SABI NETWORKS
      </h3>
      <div className="w-48 h-48 mx-auto overflow-hidden relative">
        {sabinetworks &&
          sabinetworks.map((sabinetwork, index) => (
            <div
              key={sabinetwork.id}
              className={`w-full h-full absolute transition-transform duration-1000 ${
                index === state.currentIndex
                  ? "translate-x-0"
                  : "translate-x-full"
              }`}>
              <div className=" flex flex-col justify-center items-center w-48 h-48 shadow-md bg-[#fab418] border-[0.5px] border-solid p-0 lg:p-2 gap-1">
                <img src={sabinetwork.whysabiImg} alt="" className="" />
                <p className="why-heading text-gray-700 text-sm font-semibold">
                  {sabinetwork.whysabi}
                </p>
                <p className="why-body text-gray-700 text-sm text-center leading-[90%] font-normal w-36 h-20">
                  {sabinetwork.whysabiDetails}
                </p>
              </div>
            </div>
          ))}

        {/* <WhySabiCard /> */}
      </div>
    </div>
  );
};
