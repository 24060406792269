import React from "react";
import StarRate from "./stars";
import {useState} from "react";
import nextbtn from "../assets/icons/next-btn.svg";
import prevbtn from "../assets/icons/prev-btn.svg";

export const TestimonialCard = (props) => {
  const [currentCard, setCurrentCard] = useState(0);

  const goToNextSlide = () => {
    setCurrentCard((prevIndex) => (prevIndex + 1) % props.testimonials.length);
  };

  const goToPrevSlide = () => {
    setCurrentCard(
      (prevIndex) =>
        (prevIndex - 1 + props.testimonials.length) % props.testimonials.length
    );
  };

  return (
    <div className="carousel relative">
      <div className="carousel-btns absolute -bottom-10 right-1/3 gap-[30px] lg:gap-[120px] flex justify-between items-center">
        <div
          alt="prev-btn"
          onClick={goToPrevSlide}
          className="prev-card flex justify-center items-center bg-white w-9 h-9 rounded-full shadow-lg hover:bg-[#00a85a]">
          <img src={prevbtn} alt="" />
        </div>
        <div
          alt="next-btn"
          onClick={goToNextSlide}
          className="next-card flex justify-center items-center bg-white w-9 h-9 rounded-full shadow-lg hover:bg-[#00a85a]">
          <img src={nextbtn} alt="" />
        </div>
      </div>

      {props.testimonials &&
        props.testimonials.map((testimonial) => {
          return (
            <div
              className="testimonial-card flex min-h-min py-4 lg:py-10 px-3 lg:px-20 justify-center items-start lg:max-w-2xl rounded-3xl bg-white gap-2 lg:gap-4 shadow-md"
              key={testimonial.id}>
              <img
                src={testimonial.imgcard}
                className="card-img h-10 lg:h-20 w-10 lg:w-20 rounded-xl"
                alt="testimonial"
              />
              <div className="testimonial-details flex flex-col items-start lg:gap-5">
                <p className="testimonial-comment lg:w-[399px] text-gray-700 text-justify text-xs lg:text-base font-normal ">
                  {testimonial.comment}
                </p>
                <div className="name-stars flex flex-col items-start lg:gap-2">
                  <p className="testimonial-name text-gray-700 lg:text-xl font-semibold">
                    {testimonial.testimonialname}
                  </p>
                  <div className="stars flex items-start w-48 h-5 gap-2">
                    <StarRate rating={testimonial.rating} />
                  </div>
                </div>
              </div>
            </div>
          );
        })[currentCard]}
    </div>
  );
};
